import axios from 'axios';

export const BASE_URL = 'https://youtube-v31.p.rapidapi.com';

const options = {
  params: {
    channelId: 'UCNSe9qS4kJRbFp_MDjQrDyQ',
    maxResults: 50,
    order: 'date',
    type: 'video',
  },
  headers: {
    'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
    'X-RapidAPI-Host': 'youtube-v31.p.rapidapi.com',
  },
};

export const fetchFromAPI = async (url) => {
  const { data } = await axios.get(`${BASE_URL}/${url}`, options);
  return data;
};

// Define a function to fetch the latest videos from the API every 5 minutes
export const fetchLatestVideos = () => {
  setInterval(async () => {
    try {
      // Call the fetchFromAPI function with the appropriate URL to retrieve the latest videos
      const videos = await fetchFromAPI('search');

      // Do something with the retrieved videos, such as updating state or dispatching an action
      console.log(videos);
    } catch (error) {
      console.error(error);
    }
  }, 1 * 60 * 1000); // Call the function every 5 minutes
};


// // Importing the Axios library to make HTTP requests
// import axios from 'axios';

// // Defining the base URL for the API
// export const BASE_URL = 'https://youtube-v31.p.rapidapi.com';


// // Setting the options object for the API request
// const options = {
//   params: {
//     channelId: 'UCNSe9qS4kJRbFp_MDjQrDyQ', // Channel ID parameter for the request
//     maxResults: 50, // Maximum number of results parameter for the request
//     order: 'date', // Order videos by date (most recently uploaded first)
//     type: 'video', // Retrieve only video results
//   },
//   headers: {
//     // API key header for authentication
//     'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
//     // API host header for endpoint routing
//     'X-RapidAPI-Host': 'youtube-v31.p.rapidapi.com',
//   },
// };


// // Exporting a function to fetch data from the API given a URL
// export const fetchFromAPI = async (url) => {
//   // Making a GET request to the API using Axios and passing in the options object
//   const { data } = await axios.get(`${BASE_URL}/${url}`, options);

//   // Returning the data from the response
//   return data;
// };

{/*This code defines a module that exports a function called fetchFromAPI which can be used to fetch data from the YouTube Data API v3.

The function uses the Axios library to make a GET request to the API with the specified options object which includes the API key header for authentication and the API host header for endpoint routing.

The function takes a URL as an argument which is appended to the BASE_URL constant to form the full API request URL. Once the data is received from the API, the function returns the data property of the response object.

The options object in this code includes a channelId parameter with the value UCNSe9qS4kJRbFp_MDjQrDyQ which is the channel ID for the "Google Developers" YouTube channel, and a maxResults parameter with the value of 50 which specifies the maximum number of results to be returned in the API response.*/}