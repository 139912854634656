// Import React library and components from Material-UI and React Router
import React from 'react'
import { Link } from "react-router-dom"; 
import { Typography, Card, CardContent, CardMedia } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Import constants from utils directory
import { demoThumbnailUrl, demoVideoUrl, demoVideoTitle, demoChannelUrl, demoChannelTitle } from "../utils/constants";

// Define a component called VideoCard and pass props to it
const VideoCard = ({ video: { id: { videoId }, snippet } }) => (
  // Return a Card component with width, box shadow, and border radius styles
  <Card style={{fontStyle:'Manrope'}} sx={{ width: { xs: '100%', sm: '358px', md: "355px", }, boxShadow: "none", borderRadius: 0 }}>
    {/* Link to the video page using videoId if it exists, else use default video */}
    <Link to={videoId ? `/video/${videoId}` : `/video/sOivt_VRq5A` }>
      {/* Display the video thumbnail with width, height and alt text */}
      
      <CardMedia image={snippet?.thumbnails?.high?.url || demoThumbnailUrl} alt={snippet?.title} 
        sx={{ width: { xs: '100%', sm: '358px'}, height: 180 }} 
      />
    </Link>
    {/* Display the video title and channel */}
    <CardContent sx={{ backgroundColor: "#1E1E1E", height: '106px' }}>
      {/* Link to the video page using videoId if it exists, else use default video */}
      <Link to={videoId ? `/video/${videoId}` : demoVideoUrl } >
        {/* Display the video title with maximum 60 characters */}
        <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
          {snippet?.title.slice(0, 60) || demoVideoTitle.slice(0, 60)}
        </Typography>
      </Link>
      {/* Link to the channel page using channelId if it exists, else use default channel */}
      <Link to={snippet?.channelId ? `/channel/${snippet?.channelId}` : demoChannelUrl} >
        {/* Display the channel title and a check circle icon */}
        <Typography variant="subtitle2" color="gray">
          {snippet?.channelTitle || demoChannelTitle}
          <CheckCircleIcon sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
        </Typography>
      </Link>
    </CardContent>
  </Card>
);

// Export the VideoCard component as the default export
export default VideoCard


{/*This component defines a video card that displays information about a YouTube video, including its thumbnail, title, and channel information. The component takes in a prop that contains information about the video, including its video ID and snippet (which includes the title, thumbnail, and channel information). The card is styled with a specific width, box shadow, and border radius, and it includes a link to the video page that uses the video ID as the route parameter. If no video ID is provided, it defaults to a specific demo video. The card includes the video thumbnail, title (limited to 60 characters), and channel information (including the channel title and a check circle icon). The channel information also includes a link to the channel page, which uses the channel ID as the route parameter. If no channel ID is provided, it defaults to a specific demo channel.*/}