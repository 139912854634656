// Import required dependencies
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography, Box, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Import custom components and utility function
import { Videos, Loader } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";

// Define the VideoDetail component
const VideoDetail = () => {
  
  // Define state variables and initialize with null values
  const [videoDetail, setVideoDetail] = useState(null);
  const [videos, setVideos] = useState(null);
  
  // Get the video id from the URL parameter using the useParams() hook
  const { id } = useParams();

  // Load the video details and related videos when the component is mounted
  useEffect(() => {
    // Fetch the video details using the YouTube Data API and update the state
    fetchFromAPI(`videos?part=snippet,statistics&id=${id}`)
      .then((data) => setVideoDetail(data.items[0]))

    // Fetch the related videos using the YouTube Data API and update the state
    fetchFromAPI(`search?part=snippet&relatedToVideoId=${id}&type=video`)
      .then((data) => setVideos(data.items))
  }, [id]);

  // If video details are not available, show the Loader component
  if(!videoDetail?.snippet) return <Loader />;

  // Destructure the video details to get the required data
  const { snippet: { title, channelId, channelTitle }, statistics: { viewCount, likeCount } } = videoDetail;

  // Render the VideoDetail component
  return (
    <Box minHeight="95vh"
    style={{fontStyle:'Manrope'}}
    >
      <Stack direction={{ xs: "column", md: "row" }}>
        <Box flex={1}>
          <Box sx={{ width: "100%", position: "sticky", top: "86px" }}>
            {/* Render the video player */}
            <ReactPlayer url={`https://www.youtube.com/watch?v=${id}`} className="react-player" controls />
            {/* Render the video title */}
            <Typography color="#fff" variant="h5" fontWeight="bold" p={2}>
              {title}
            </Typography>
            {/* Render the channel details */}
            <Stack direction="row" justifyContent="space-between" sx={{ color: "#fff" }} py={1} px={2} >
              <Link to={`/channel/${channelId}`}>
                <Typography variant={{ sm: "subtitle1", md: 'h6' }}  color="#fff" >
                  {channelTitle}
                  <CheckCircleIcon sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
                </Typography>
              </Link>
              {/* Render the view and like count */}
              <Stack direction="row" gap="20px" alignItems="center">
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {parseInt(viewCount).toLocaleString()} views
                </Typography>
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {parseInt(likeCount).toLocaleString()} likes
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
        {/* Render the related videos */}
        <Box px={2} py={{ md: 1, xs: 5 }} justifyContent="center" alignItems="center" >
          <Videos videos={videos} direction="column" />
        </Box>
      </Stack>
    </Box>
 

  );
};

//export the component
export default VideoDetail;

{/*This is a React component called VideoDetail that displays the details of a single video, including the video player, title, channel details, and related videos.

The component fetches the video details and related videos using the YouTube Data API when the component is mounted, using the video ID obtained from the URL parameter using the useParams() hook. The fetched data is stored in state variables using the useState() hook.

The component first displays a loading spinner if the video details are not available. When the video details are available, it displays the video player with the video title, channel details (including the channel name and checkmark icon indicating the channel is verified), and view and like count. It also displays a list of related videos below the main video player.

The component uses various Material-UI components such as Box, Stack, Typography, and CheckCircleIcon to create a responsive and visually appealing layout. It also uses a custom Videos component and a Loader component imported from other files in the same directory. The component exports itself as a default export to be used in other parts of the application.
 */}