// Importing required modules from React and other libraries
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

// Importing components from current and utils directory
import { Videos, ChannelCard } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";

// Defining a functional component
const ChannelDetail = () => {
  
  // Initializing state variables using useState hook
  const [channelDetail, setChannelDetail] = useState();
  const [videos, setVideos] = useState(null);

  // Extracting the `id` from the URL parameters
  const { id } = useParams();

  // Defining a side effect using useEffect hook
  useEffect(() => {
    const fetchResults = async () => {
      // Fetching channel data from the API using `fetchFromAPI` function
      const data = await fetchFromAPI(`channels?part=snippet&id=${id}`);

      // Updating the state variable `channelDetail`
      setChannelDetail(data?.items[0]);

      // Fetching video data from the API using `fetchFromAPI` function
      const videosData = await fetchFromAPI(`search?channelId=${id}&part=snippet%2Cid&order=date`);

      // Updating the state variable `videos`
      setVideos(videosData?.items);
    };

    // Invoking the `fetchResults` function
    fetchResults();
  }, [id]); // Triggering the side effect only when `id` changes

  // Returning JSX
  return (
    <Box minHeight="95vh">
      <Box>
        {/* Styling the gradient background */}
        <div style={{
          height:'250px',
          background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(16,12,122,1) 54%, rgba(8,30,54,1) 100%, rgba(0,212,255,1) 100%)',
          zIndex: 10,
        }} />
        {/* Rendering the ChannelCard component */}
        <ChannelCard channelDetail={channelDetail} marginTop="-93px" />
      </Box>
      <Box p={2} display="flex">
        {/* Rendering the Videos component */}
        <Box sx={{ mr: { sm: '100px' } }}/>
        <Videos videos={videos} />
      </Box>
    </Box>
  );
};

// Exporting the component as default
export default ChannelDetail;

{/*This component is a React functional component that fetches data for a specific YouTube channel using the YouTube Data API, and displays the details of the channel along with its videos. The component uses the useEffect hook to fetch the data when the id parameter changes, and then sets the state variables channelDetail and videos accordingly.

The ChannelDetail component returns JSX that displays the channel's details along with its videos. The channel's details are displayed using the ChannelCard component, which passes the channelDetail data as a prop. The channel's videos are displayed using the Videos component, which passes the videos data as a prop. The Box component is used for styling and layout purposes.*/}