// Importing Stack component from Material-UI library
import { Stack } from "@mui/material";
// Importing Link component from react-router-dom library
import { Link } from "react-router-dom";

// Importing SearchBar component from local file
import { SearchBar } from "./";

// Defining Navbar functional component
const Navbar = () => (
// Creating a horizontal Stack component with some styles
<Stack direction="row" zIndex="2" alignItems="center" p={2} sx={{ position: "sticky", background: '#031B34', top: 0, justifyContent: "space-between" }}>
{/* Creating a Link component to home page */}
<Link to="/" 
style={{ display: "flex", alignItems: "center", marginLeft:'55px', color:'white', 
fontFamily:"Manrope"
}}>
<h1>Watch</h1>
</Link>
{/* Rendering SearchBar component */}
<SearchBar />
</Stack>
);

// Exporting Navbar component as default
export default Navbar;
