// Import the necessary libraries
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Paper, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// Define the functional component named SearchBar
const SearchBar = () => {

  // Declare a state variable called searchTerm and its setter function using the useState hook
  const [searchTerm, setSearchTerm] = useState('');

  // Declare a variable called navigate and assign it the value of the useNavigate hook
  const navigate = useNavigate();

  // Define a function named onhandleSubmit that takes an event as an argument
  const onhandleSubmit = (e) => {

     // Prevent the default form submission behavior
    e.preventDefault();

    // If the searchTerm is not empty
    if (searchTerm) {
      // Navigate to the search result page with the searchTerm as a parameter
      navigate(`/search/${searchTerm}`);

      // Reset the searchTerm to an empty string
      setSearchTerm('');
    }
  };

  // Return the JSX elements to render
  return (

    // Use the Paper component to create a container for the search bar
    <Paper
      component='form'
      onSubmit={onhandleSubmit} // Call onhandleSubmit when the form is submitted
      sx={{
        borderRadius: 20,
        border: '1px solid #e3e3e3',
        pl: 2,
        boxShadow: 'none',
        mr: { sm: 5 },
      }}
    >
      <input
        className='search-bar'
        placeholder='Search...' // Display the placeholder text inside the input field
        value={searchTerm} // Set the value of the input field to the searchTerm state variable
        onChange={(e) => setSearchTerm(e.target.value)} // Call setSearchTerm when the input field value changes
      />
      
      {/* Use the IconButton component to create a search button */}
      <IconButton type='submit' sx={{ p: '10px', color: 'red' }}  aria-label='search'>
        <SearchIcon /> {/*Use the SearchIcon component as the search icon */}
      </IconButton>
    </Paper>
  );
};
// Export the SearchBar component as the default export
export default SearchBar;


{/*The code defines a functional component called "SearchBar" using JSX syntax. The component renders a Paper component which contains an input field and a search button.

The component uses the useState hook to declare a state variable called "searchTerm" and its corresponding setter function. It also uses the useNavigate hook from react-router-dom to declare a variable called "navigate" which is used to navigate to the search results page.

The component defines a function called "onhandleSubmit" that is called when the search form is submitted. This function prevents the default form submission behavior, checks if the searchTerm is not empty, and navigates to the search results page with the searchTerm as a parameter. It also resets the searchTerm to an empty string.

The Paper component is used to create a container for the search bar with some styles such as setting the borderRadius, border, and boxShadow properties. The input field uses the value of the searchTerm state variable and updates it when the input field value changes. The search button is created using the IconButton component and uses the SearchIcon component as its icon.

Finally, the SearchBar component is exported as the default export. This component can be imported and used in other parts of the application to provide search functionality.
*/}