// Importing necessary dependencies from various libraries
import { useState, useEffect } from "react"; // useState and useEffect from React
import { Typography, Box } from "@mui/material"; // Typography and Box components from Material UI library
import { useParams } from "react-router-dom"; // useParams hook from React Router DOM library

import { fetchFromAPI } from "../utils/fetchFromAPI"; // Custom fetchFromAPI function
import { Videos } from "./";  // Custom Videos component

// Define a functional component named SearchFeed
const SearchFeed = () => {

  // Initializing the state hook to hold videos data as null
  const [videos, setVideos] = useState(null);

  // Extracting search term from URL parameter using useParams hook
  const { searchTerm } = useParams();

  // Using useEffect hook to fetch videos data from API based on search term
  useEffect(() => {

    // Calling custom fetchFromAPI function and passing search term as argument
    fetchFromAPI(`search?part=snippet&q=${searchTerm}`)    
      .then((data) => setVideos(data.items))// Updating videos state with fetched data
  }, [searchTerm]);

  // Return JSX code for displaying search feed component
  return (
    <Box p={2} minHeight="95vh" style={{fontStyle:'Manrope'}}>
      <Typography variant="h4" fontWeight={900}  color="white" mb={3} ml={{ sm: "100px"}}>
        Search Results for <span style={{ color: "#ff4820" }}>{searchTerm}</span> videos
      </Typography>
      <Box display="flex">
        <Box sx={{ mr: { sm: '100px' } }}/>
        {<Videos videos={videos} />}
      </Box>
    </Box>
  );
};
// Exporting SearchFeed component as default
export default SearchFeed;

{/*This is a functional component named SearchFeed that displays a list of videos based on the search term extracted from the URL parameter using the useParams hook from React Router DOM.

The component imports necessary dependencies from various libraries including useState and useEffect hooks from React, Typography and Box components from the Material UI library, useParams hook from React Router DOM library, a custom fetchFromAPI function for fetching data from the API and a custom Videos component.

The component initializes a state hook to hold videos data as null and uses the useEffect hook to fetch videos data from the API based on the search term. The fetched data is then used to update the videos state using the setVideos function.

The component returns JSX code for displaying the search feed component, which includes the search term, and a list of videos rendered using the custom Videos component.
*/}