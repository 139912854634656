import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Videos, Sidebar } from "./";
import { fetchFromAPI } from "../utils/fetchFromAPI";

const Feed = () => {
  const [selectedCategory, setSelectedCategory] = useState("New");
  const [videos, setVideos] = useState(null);

  const fetchVideos = (category) => {
    setVideos(null);
    fetchFromAPI(`search?part=snippet&q=${category}`)
      .then((data) => setVideos(data.items));
  };

  const fetchLatestVideos = () => {
    fetchFromAPI(
      "search?part=snippet&channelId=UCNSe9qS4kJRbFp_MDjQrDyQ&maxResults=50&order=date&type=video"
    ).then((data) => setVideos(data.items));
    setTimeout(fetchLatestVideos, 300000); // Fetch latest videos every 5 minutes
  };

  useEffect(() => {
    fetchVideos(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    fetchLatestVideos();
  }, []);

  return (
    <Stack sx={{ flexDirection: { xs: "column", md: "row" } }}>
      <Box
        sx={{
          height: { xs: "auto", md: "92vh" },
          borderRight: "1px solid #031B34",
          px: { xs: 0, md: 2 },
        }}
      >
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <Typography
          variant="body2"
          sx={{ mt: 1.5, color: "#fff" }}
          align="center"
        >
          © 2023 Ablaze Media
        </Typography>
      </Box>
      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
        <Typography variant="h4" fontWeight="bold" mb={2} sx={{ color: "white" }}>
          {selectedCategory} <span style={{ color: "#FC1503" }}>videos</span>
        </Typography>
        <Videos videos={videos} />
      </Box>
    </Stack>
  );
};

export default Feed;
