// Importing necessary dependencies
import React from 'react';
import { Box, CardContent, CardMedia, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import { demoProfilePicture } from '../utils/constants';

// Defining a functional component named ChannelCard
const ChannelCard = ({ channelDetail, marginTop }) => (
  // Rendering a Box component with custom styles and props
  <Box
    sx={{
    fontStyle:'Manrope',
    boxShadow: 'none',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: { xs: '400px', md: '320px' },
    height: '400px',
    margin: 'auto',
    marginTop,
    }}
  >
    {/* Rendering a Link component with custom props */}
    <Link to={`/channel/${channelDetail?.id?.channelId}`}>
      {/* Rendering a CardContent component with custom styles */}
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', color: '#fff' }}>
        {/* Rendering a CardMedia component with custom styles and props */}
        <CardMedia
          image={channelDetail?.snippet?.thumbnails?.high?.url || demoProfilePicture}
          alt={channelDetail?.snippet?.title}
          sx={{ borderRadius: '50%', height: '180px', width: '180px', mb: 2, border: '1px solid #e3e3e3',marginLeft:'55px' }}
        />
        {/* Rendering a Typography component with custom props */}
        <Typography variant="h6">
          {channelDetail?.snippet?.title}{' '}
          {/* Rendering a CheckCircleIcon component with custom props */}
          <CheckCircleIcon sx={{ fontSize: '14px', color: 'gray', ml: '5px' }} />
        </Typography>
        {/* Rendering a Typography component with custom styles and props if a specific condition is true */}
        {channelDetail?.statistics?.subscriberCount && (
          <Typography sx={{ fontSize: '15px', fontWeight: 500, color: 'gray' }}>
            {parseInt(channelDetail?.statistics?.subscriberCount).toLocaleString('en-US')} Subscribers
          </Typography>
        )}
      </CardContent>
    </Link>
  </Box>
);

// Exporting the ChannelCard component as a default export
export default ChannelCard;


{/*This is a React functional component named ChannelCard that renders a card-like UI element displaying information about a YouTube channel.

The component receives two props: channelDetail and marginTop. The channelDetail prop is an object containing the details of the channel, including its id, snippet, and statistics. The marginTop prop is a number specifying the top margin of the Box component.

The component first imports several necessary dependencies from the Material UI library, including Box, CardContent, CardMedia, and Typography. It also imports the CheckCircleIcon component from the Material UI Icons library and the Link component from the React Router DOM library. Finally, it imports a constant demoProfilePicture from a constants file.

Inside the component, it renders a Box component with custom styles and props, including the specified marginTop. Inside the Box component, it renders a Link component that links to the channel's details page using the channelDetail prop's id.channelId property.

Inside the Link component, it renders a CardContent component with custom styles. Inside the CardContent component, it renders a CardMedia component that displays the channel's profile picture or a default picture if it's missing. The CardMedia component has custom styles, including a circular border and a size of 180px by 180px. It also renders a Typography component with the channel's name and a CheckCircleIcon component indicating that the channel is verified.

If the channelDetail.statistics.subscriberCount property exists, the component also renders a Typography component with the channel's number of subscribers, which is formatted with commas to separate thousands.

        Finally, the component exports the ChannelCard component as a default export, allowing other modules to import and use it in their React applications.*/}




