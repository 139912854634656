import MusicNoteIcon from '@mui/icons-material/MusicNote';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

// export const logo = 'https://i.ibb.co/s9Qys2j/logo.png';

export const categories = [
  // { name: 'Community Church', icon: <HomeIcon />, },
  // { name: 'Livestream', icon: <LiveTvIcon />, },
  { name: 'Sermons', icon: <OndemandVideoIcon />, },
  { name: 'Bible Study', icon: <SchoolIcon />, },
  { name: 'Bible Journaling', icon: <SchoolIcon />, },
  { name: 'Video Podcasts', icon: <GraphicEqIcon />, },
  { name: 'Corporate Connect', icon: <DeveloperModeIcon />, },
  { name: 'Youth Church', icon: <SchoolIcon />, },
  { name: 'ARK Ministry', icon: <SchoolIcon />, },
  { name: 'WOW Ministry', icon: <FaceRetouchingNaturalIcon />, },
  { name: 'Praise & Worship ', icon: <MusicNoteIcon /> }, 
  // { name: 'Movies & Series', icon: <OndemandVideoIcon />, },
  // { name: 'Animated Series', icon: <OndemandVideoIcon />, },
  // { name: 'Current Affairs', icon: <OndemandVideoIcon />, },
  { name: 'Health & Fitness', icon: <FitnessCenterIcon />, },
];

export const demoThumbnailUrl = 'https://i.ibb.co/G2L2Gwp/API-Course.png';
export const demoChannelUrl = '/channel/UCmXmlB4-UCNSe9qS4kJRbFp_MDjQrDyQ';
export const demoVideoUrl = '/video/sOivt_VRq5A';
export const demoChannelTitle ='Community Church Ministries International';
export const demoVideoTitle = 'Community Church';
export const demoProfilePicture = 'http://dergipark.org.tr/assets/app/images/buddy_sample.png'