// Importing the React library and necessary components from Material UI
import React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';

// Defining a functional component called Loader
const Loader = () =>  (
  // Returning a Box component with a minimum height of 95 viewport height units
  <Box minHeight="95vh">
    {/* Returning a Stack component with a row direction, center justification, and center alignment, with a height of 80 viewport height units */}
    <Stack direction='row' justifyContent='center' alignItems='center' height='80vh' >
      {/* Returning a CircularProgress component to display a loading spinner */}
      <CircularProgress />
    </Stack>
  </Box>
);

// Exporting the Loader component as the default export of this module
export default Loader;

{/*This component defines a functional component called Loader, which is exported as the default export of this module. The Loader component renders a full-screen loading spinner that is centered on the screen.

The Loader component uses the Box component to create a minimum height of 95 viewport height units to cover the entire screen. Within the Box component, the Stack component is used to center the loading spinner both horizontally and vertically on the screen. The CircularProgress component from Material UI is used to display the loading spinner.

In summary, the Loader component is a user interface element that is displayed while waiting for data to load. It gives the user feedback that the application is working and helps to prevent frustration caused by a slow or unresponsive application.
*/}